<template>
  <div id="logindiv">
    <div id="login">
      <h3 style="margin-bottom:20px">
        <span>{{webname}}</span>系统运营商后台
      </h3>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-user" type="text" v-model="tel" placeholder="请输入手机号码" />
      </el-row>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-key" type="password" v-model="password" placeholder="请输入密码"  @keyup.enter.native="submitForm" />
      </el-row>
      <el-row class="margin-large-bottom text-center">
        <el-button type="primary" @click="submitForm">登录</el-button>
      </el-row>
    </div>
    <div class="text-center" style="position:absolute;bottom:0;width:100%;">
      <a href="http://beian.miit.gov.cn">鲁ICP备20002132号-1</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PuzzleVerification from "../../plugins/verify.js";
export default {
  components: {
    PuzzleVerification
  },
  data() {
    return {
      tel: "",
      password: "",
      verify: "",
      webname: this.WEBNAME,
    };
  },
  mounted() {
    // this.request();
  },
  methods: {
    ...mapActions({
      login: "auth/login"
    }),
    submitForm() {
      if (this.tel == "") {
        this.$message({
          type: "error",
          message: "请输入手机号"
        });
        return;
      }
      if (this.password == "") {
        this.$message({
          type: "error",
          message: "请输入密码"
        });
        return;
      }
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/login/login",
        data: {
          tel: this.tel,
          password: this.password
        }
      }).then(res => {
        // console.log(res);
        sessionStorage.setItem("jiameng_id", res.data.userinfo.uuid);
        sessionStorage.setItem("jiameng_name", res.data.userinfo.name);
        sessionStorage.setItem("jiameng_token", res.data.token);
        sessionStorage.setItem("jiameng_level", res.data.userinfo.level);
        sessionStorage.setItem("jiameng_login", true);
        sessionStorage.setItem(
          "jiameng_menulist",
          JSON.stringify(res.data.menulist)
        );
        let id = res.data.menulist[0][0]["id"];
        // console.log(res.data.menulist[id]);
        let url = res.data.menulist[id][0]["url"];
        // console.log(res.data.menulist[0][0]['id']);
        this.$router.push(url);
      });
    }
  }
};
</script>

<style>
#logindiv {
  background-image: url(../../assets/images/admin/login/login_bg.jpg);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#login {
  width: 600px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 25%;
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
}
.puzzle-box {
  box-sizing: border-box;
}
.puzzle-container {
  box-sizing: border-box;
  width: 100%;
  display: inline-block !important;
}

@media screen and (max-width: 768px) {
  #puzzle-box,
  .slider-container {
    width: 100% !important;
  }
  .el-dialog {
    width: 80% !important;
  }
}
</style>
